

































































































































































































































import { defineComponent, Ref, ref, computed, ComputedRef, reactive } from '@vue/composition-api';
import { useUser, User } from '@/admin/user';
import { usePayment, SubscriptionPlan, addSubscriptionPlan, SubscriptionProduct } from '@/admin/payment';
import { useNotification } from '@/composition/notification';
import {
  History,
  HistoryBase,
  PostUseHistories,
  useHistory,
  toggleUse,
  GiftHistoryProps,
  addGift,
} from '@/admin/history';
import { getGifts, GiftResponse } from '@/admin/gift';
import { useFavorites } from '@/composition/favorites';
import { getProducts, ProductResponse } from '@/admin/product';
import { getOptionsTitle } from '@/admin/util';
import { useConfirm } from '@/composition/confirm';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

interface UserSubscriptionPlan extends SubscriptionPlan {
  displayName: string;
  isAdd: boolean;
}
interface DisplayGift extends HistoryBase {
  giftName: string;
  isUsable: boolean;
  children: History[];
}

interface ProductHistory extends HistoryBase {
  productName: string;
  children: History[];
}

export default defineComponent({
  name: 'User',
  components: {
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    userId: {
      type: String,
      require: false,
      default: '',
    },
    prevPage: {
      type: String,
      required: false,
      default: '/users',
    },
  },
  setup({ userId }) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('users'));

    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const { favorites } = useFavorites();
    const { userProperties, getUsers, updateNote, removeNote } = useUser();
    const { getSubscriptionPlans, getSubscriptionProducts } = usePayment();
    const { getHistories } = useHistory();
    const plans: Ref<SubscriptionPlan[]> = ref([]);
    const subscriptionProducts: Ref<SubscriptionProduct[]> = ref([]);
    getSubscriptionPlans().then(
      (_plans) => (plans.value = _plans.filter((plan) => !plan.isArchive)),
      (e) => console.error(e)
    );
    getSubscriptionProducts().then(
      (_products) => (subscriptionProducts.value = _products),
      (e) => console.error(e)
    );

    const selectedId = ref(userId);
    const users: Ref<User[]> = ref([]);
    const user: ComputedRef<User | null> = computed(
      () => users.value.find((user) => user.userId === selectedId.value) || null
    );
    const userPropertyKey = computed(() => [
      { text: 'ユーザーID', value: 'userId' },
      { text: 'メールアドレス', value: 'email' },
      ...userProperties.value.map(({ key, title }) => ({ text: title, value: `properties.${key}` })),
      { text: '会員番号', value: 'userNumber' },
      { text: '登録日', value: 'createDate' },
      { text: '退会日', value: 'deleteDate' },
    ]);
    const isLoading = ref(true);
    getUsers()
      .then((result) => {
        users.value = result;
        isLoading.value = false;
      })
      .catch((error) => {
        notification.error(error);
        isLoading.value = false;
      });
    const getPropertyValue = (key: string) => {
      const value = key.split('.').reduce((acc: any, val) => {
        if (!acc) acc = '';
        else if (user.value) acc = acc[val];
        return acc;
      }, user.value);
      return Array.isArray(value) ? value.join(', ') : value;
    };
    // 加入済みの商品ID一覧
    const entryProductIds = computed(
      () =>
        user.value?.displaySubscriptionPlans
          .map(
            (subscriptionPlan) =>
              plans.value.find((plan) => subscriptionPlan.value === plan.subscriptionPlanId)?.subscriptionProductId ||
              ''
          )
          .filter((item) => item) || []
    );
    // プラン追加
    const subscriptionPlans = computed(() => {
      // 商品ID一覧：プランのorder順に基づいた商品IDの配列作成
      const subscriptionProductIds = plans.value
        .map((plan) => plan.subscriptionProductId)
        .filter((id, index, productIds) => index === productIds.indexOf(id));
      return subscriptionProductIds.reduce((acc_plans, val_productId) => {
        //商品名：商品ID（val_productID）に紐づく商品名取得
        const subscriptionProductName =
          subscriptionProducts.value.find((product) => val_productId === product.subscriptionProductId)
            ?.subscriptionProductName || '';
        if (subscriptionProductName) {
          const productPlans = plans.value
            .filter((plan) => val_productId === plan.subscriptionProductId) //商品のプランのみにフィルター
            .map((plan) => {
              //加入ボタンのフラグ：加入している商品じゃないプランのみtrue
              const isAdd = !entryProductIds.value.includes(plan.subscriptionProductId);
              //表示されるプラン名：加入プラン一覧の場合、加入中をつけてあげる
              const subscriptionPlan = {
                text: plan.subscriptionPlanName,
                value: plan.subscriptionPlanId,
              };
              const displayName = user.value?.displaySubscriptionPlans.some(
                (plan) => plan.value === subscriptionPlan.value
              )
                ? `（加入中）【${subscriptionProductName}】${plan.subscriptionPlanName}`
                : `【${subscriptionProductName}】${plan.subscriptionPlanName}`;
              return {
                displayName,
                isAdd,
                ...plan,
              };
            });
          acc_plans.push(...productPlans);
        }
        return acc_plans;
      }, [] as UserSubscriptionPlan[]);
    });
    const isActivePlanDialog = ref(false);
    const isAdding = ref(false);
    const openPlanDialog = () => {
      isActivePlanDialog.value = true;
    };
    const addPlan = async (subscriptionPlanId: string) => {
      if (!user.value) return;
      if (!(await confirmDialog('本当に加入させますか？'))) return;
      isAdding.value = true;
      try {
        await addSubscriptionPlan({ endUsername: user.value.userId, subscriptionPlanId });
        const index = users.value.indexOf(user.value);
        const subscriptionPlan = plans.value.find((plan) => plan.subscriptionPlanId === subscriptionPlanId);
        if (subscriptionPlan) {
          users.value[index].displaySubscriptionPlans = users.value[index].displaySubscriptionPlans.filter(
            (plan) => !['freePlan', 'paymentError', 'uncompleted'].includes(plan.value)
          );
          users.value[index].displaySubscriptionPlans.push({
            text: subscriptionPlan.subscriptionPlanName,
            value: subscriptionPlan.subscriptionPlanId,
          });
          users.value[index].subscriptionNames = users.value[index].displaySubscriptionPlans.map((plan) => plan.text);
          users.value[index].subscriptionPlanIds = users.value[index].displaySubscriptionPlans.map(
            (plan) => plan.value
          );
        }
        notification.notify('加入しました');
      } catch (e) {
        notification.error(e);
      }
      isAdding.value = false;
    };

    // 商品購入履歴を取得
    const productHistories: Ref<History[]> = ref([]);
    getHistories('product', '', userId)
      .then((result) => {
        productHistories.value = result.histories;
      })
      .catch((error) => {
        notification.error(error);
      });

    // 商品の取得（historiesには必要な商品名などは入ってこないため）
    const _products: Ref<ProductResponse[]> = ref([]);
    getProducts()
      .then((result) => {
        _products.value = result;
      })
      .catch((error) => {
        notification.error(error);
      });

    const products: ComputedRef<ProductHistory[]> = computed(() => {
      // 重複を削除
      const _histories = productHistories.value.filter(
        (history, index, self) => self.findIndex((e) => e.productId === history.productId) === index
      );
      return _histories.map((history) => {
        const product = _products.value.find((product) => product.productId === history.productId);
        return {
          productName: product?.productName || '',
          children: productHistories.value.filter((_history) => product?.productId === _history.productId),
          ...history,
        };
      });
    });

    // ユーザーノート
    const editedNote = ref('');
    const isEditNote = ref(false);
    const editNote = () => {
      isEditNote.value = true;
      editedNote.value = user.value?.note || '';
    };
    const saveNote = () => {
      if (!user.value) return;
      const index = users.value.indexOf(user.value);
      if (typeof editedNote.value !== 'string') return; // キャンセル
      if (editedNote.value) updateNote(users.value[index], editedNote.value);
      else removeNote(users.value[index]);
      isEditNote.value = false;
    };

    // 特典の履歴を取得
    const giftHistories: Ref<History[]> = ref([]);
    getHistories('gift', '', userId)
      .then((result) => {
        giftHistories.value = result.histories;
      })
      .catch((error) => {
        notification.error(error);
      });

    // 特典の取得（historiesには必要な特典名などは入ってこないため）
    const _gifts: Ref<GiftResponse[]> = ref([]);
    getGifts().then((result) => {
      _gifts.value = result;
    });
    const giftOptions = computed(() =>
      _gifts.value.map((_gift) => {
        return { text: _gift.giftName, value: _gift.giftId };
      })
    );

    const gifts: ComputedRef<DisplayGift[]> = computed(() => {
      // 重複を削除
      const _histories = giftHistories.value.filter(
        (history, index, self) => self.findIndex((e) => e.giftId === history.giftId) === index
      );
      return _histories.map((history) => {
        const gift = _gifts.value.find((gift) => gift.giftId === history.giftId);
        return {
          isUsable: !!gift?.isUsable,
          giftName: gift?.giftName || '',
          children: giftHistories.value.filter((_history) => gift?.giftId === _history.giftId),
          ...history,
        };
      });
    });

    // 特典を追加
    const isActiveGiftDialog = ref(false);
    const isGiftAdding = ref(false);
    const openGiftDialog = () => {
      isActiveGiftDialog.value = true;
    };
    const giftData = reactive({
      giftId: '',
      quantity: 0,
    });
    const addUserGift = async () => {
      if (!(await confirmDialog('本当に特典を追加しますか？'))) return;
      isGiftAdding.value = true;
      const props: GiftHistoryProps = {
        endUsername: userId,
        giftId: giftData.giftId,
        quantity: giftData.quantity,
        historyType: 'gift',
      };
      try {
        await addGift(props);
        getHistories('gift', '', userId)
          .then((result) => {
            giftHistories.value = result.histories;
          })
          .catch((error) => {
            notification.error(error);
          });
        giftData.giftId = '';
        giftData.quantity = 0;
        notification.notify('追加しました');
      } catch (e) {
        notification.error(e);
      }
      isActiveGiftDialog.value = false;
      isGiftAdding.value = false;
    };

    // もぎりを使用する
    const isUse = ref(false);
    const use = async (item: History, type: 'product' | 'gift') => {
      if (!(await confirmDialog('本当に変更しますか？'))) return;
      isUse.value = true;
      try {
        if (item.createDate.value === undefined) return;
        const useProps: PostUseHistories = {
          endUsername: userId,
          historyType: type,
          createDate: item.createDate.value,
          isUsed: !item.isUsed,
        };
        if (type === 'product') useProps.productId = item.productId;
        else if (type === 'gift') useProps.giftId = item.giftId;
        await toggleUse(useProps);
        getHistories(type, '', userId)
          .then((result) => {
            if (type === 'product') productHistories.value = result.histories;
            else if (type === 'gift') giftHistories.value = result.histories;
          })
          .catch((error) => {
            notification.error(error);
          });
        notification.notify('変更しました。');
      } catch (e) {
        notification.error(e);
      }
      isUse.value = false;
    };

    const isPlan = process.env.VUE_APP_GROUP_TYPE === 'plan';
    const isSeason = process.env.VUE_APP_GROUP_TYPE === 'season';

    return {
      pageTitle: 'ユーザー詳細',
      myRoleSettings,
      isLoading,
      selectedId,
      user,
      users,
      userPropertyKey,
      getPropertyValue,
      editedNote,
      isEditNote,
      editNote,
      saveNote,
      subscriptionPlans,
      addPlan,
      openPlanDialog,
      isActivePlanDialog,
      openGiftDialog,
      isActiveGiftDialog,
      isAdding,
      products,
      use,
      isUse,
      giftHistories,
      gifts,
      giftData,
      giftOptions,
      isGiftAdding,
      addUserGift,
      isPlan,
      isSeason,
      favorites,
      getOptionsTitle,
    };
  },
});
